<template>

  <InputField
    :field-title="$t('DIAS.LABYRINTH.WORD_TO_REVEAL')"
    :field-name="'wordToReveal'"
    :rules="'required'"
    :type="'text'"
    :placeholder="$t('DIAS.LABYRINTH.WORD_TO_REVEAL_PLACEHOLDER')"
    :cy-selector="'name-input'"
    :value="initialData ? initialData.contentQuestionJson.wordToReveal : ''"
    :api-error-text="wordRevealError"
    :on-update="onWordRevealInput"
    class="form-element"
  />

  <div v-for="(slide,slideIndex) in currentSlides" :key="slideIndex">
    <div class="question-title">

      <h4> {{ $t('DIAS.PHISHING.QUESTION') + " " + (slideIndex + 1) }}      </h4>
      <InlineButton v-if="currentSlides.length > 1"
                    :text="$t('DIAS.NETIQUETTE.REMOVE_QUESTION')"
                    :icon="require('@/assets/icons/icn_delete.svg')"
                    @click="removeLabyrinth(slideIndex)"
      />
    </div>

    <div class="question-content-wrapper">
      <Labyrinth :slide-data="slide"
                 :show-errors="showErrors"
                 :error-messages="errorMessages[slideIndex]"
                 @image="(image) => setImage(slideIndex,image)"
                 @correct="(index) => setCorrect(slideIndex,index)"
                 @path="(index,value) => updatePath(slideIndex,index,value) "/>
    </div>
  </div>
  <InlineButton v-if="currentSlides.length < 5"
                :text="$t('DIAS.NETIQUETTE.ADD_QUESTION')"
                :icon="require('@/assets/icons/icn_add.svg')"
                :icon-color="'var(--blue_link)'"
                @click="addLabryinth()"
  />
</template>

<script>
import Labyrinth from '@/components/partials/missions/trueOrFalseTypes/Labyrinth'
import InlineButton from '../../../elements/basic/InlineButton.vue'
import InputField from '@/components/yo-form/InputField'

export default {
  name: 'AnswerLabyrinth',
  components: {
    InlineButton,
    Labyrinth,
    InputField
  },
  props: {
    initialData: {
      type: Object,
      default: null
    },
    showErrors: {
      type: Boolean,
      required: true
    }
  },
  emits: ['data', 'verify'],
  data() {
    const defaultAnswer = function(firstAnswer) {
      return {
        directions: [],
        correct: firstAnswer,
      }
    }

    const defaultSlide = () => {
      return {
        image: null,
        mediaFiles: this.initialData ? this.initialData.mediaFiles : null,
        answers: [defaultAnswer(true), defaultAnswer(false), defaultAnswer(false)]
      }
    }

    const currentSlides = this.initialData ? this.convertInitialData() : [defaultSlide()]
    //up, right, down, left
    return {
      defaultSlide,
      currentSlides,
      errorMessages: [],
      wordRevealError: '',
      revealedWord: this.initialData ? this.initialData.contentQuestionJson.wordToReveal : ''
    }
  },
  mounted() {
    this.onUpdate()
  },
  methods: {
    convertInitialData() {
      this.revealedWord = this.initialData.contentQuestionJson.wordToReveal
      const questions = this.initialData.contentQuestionJson
      const newDefaultSlides = questions.subSlides.map((slide, index) => {
        return {
          image: slide.mediaFileUrl,
          mediaFiles: this.initialData ? this.initialData.mediaFiles : null,
          answers: slide.answers.map((answer, i) => {
            return {
              directions: answer,
              correct: i === slide.correctAnswer
            }
          })
        }
      })
      return newDefaultSlides
    },
    setImage(slideIndex, image) {
      this.currentSlides[slideIndex].image = image
      this.onUpdate()
    },
    setCorrect(slideIndex, index) {
      for (let i = 0; i < this.currentSlides[slideIndex].answers.length; i++) {
        this.currentSlides[slideIndex].answers[i].correct = index === i
      }
      this.onUpdate()
    },
    updatePath(slideIndex, index, value) {
      this.currentSlides[slideIndex].answers[index].directions = value
      this.onUpdate()
    },
    removeLabyrinth(slideIndex) {
      this.currentSlides.splice(slideIndex, 1)
      this.onUpdate()
    },
    addLabryinth() {
      this.currentSlides.push(this.defaultSlide())
      this.onUpdate()
    },
    onUpdate() {
      this.$emit('data', {
        image: this.image,
        slides: this.currentSlides
      })
      this.$emit('verify', this.isValid())
    },
    isValid() {
      let success = true

      if (this.revealedWord.length !== this.currentSlides.length) {
        this.wordRevealError = 'De hoeveelheid letters moet evenveel zijn als het aantal vragen.'
      } else {
        this.wordRevealError = ''
      }

      for (let i = 0; i < this.currentSlides.length; i++) {
        const slide = this.currentSlides[i]
        for (let j = 0; j < slide.answers.length; j++) {
          this.errorMessages[i] ??= []
          this.errorMessages[i].directions ??= []
          if (slide.answers[j].directions.length === 0) {
            success = false
            this.errorMessages[i].directions[j] = 'Vul een antwoord in.'
          } else {
            this.errorMessages[i].directions[j] = ''
          }
        }

        if (slide.image === null) {
          this.errorMessages[i].noImage = 'Voeg een bestand toe.'
          success = false
        } else {
          this.errorMessages[i].noImage = ''
        }
      }

      if (!success) {
        return false
      }
      return true
    },
    onWordRevealInput(input) {
      this.revealedWord = input
      this.onUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.question-content-wrapper{
  margin: rem(20) rem(0);
  padding-left: rem(20);
  border-left: black 2px solid;
}

.question-title{
  display: flex;
  margin-bottom: rem(10);
  justify-content: space-between;
}

</style>
