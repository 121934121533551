<template>
  <div>

    <ImageInput
      :start-file="slideData.image"
      :media-files="slideData ? slideData.mediaFiles : null"

      :error-text="errorMessages && showErrors ? errorMessages.noImage : ''"
      @file="setImage"
    />
    <div v-for="(answer,index) in slideData.answers" :key="index" class="labryinth-container">
      <h4> {{ $t('CREATE_FAQ.ANSWER') + " " + String.fromCharCode(index + 65) }}</h4>
      <LabryinthDirection ref="direction" :path="answer.directions" @path="(value) => updatePath(index,value)">
      </LabryinthDirection>
      <CorrectOrDelete class="labryrinth-toggle"
                       :single="true"
                       :start-correct="answer.correct"
                       :show-remove="false"
                       @click="setCorrect(index)"/>
      <p v-if="showErrors" class="text-error">{{ errorMessages ? errorMessages.directions[index] : '' }}</p>
    </div>

  </div>
</template>

<script>

import ImageInput from '@/components/elements/inputFields/ImageInput'
import LabryinthDirection from '@/components/elements/inputFields/LabryinthDirection.vue'
import CorrectOrDelete from '@/components/elements/inputFields/CorrectOrDelete'

export default {
  name: 'Labyrinth',
  components: {
    ImageInput,
    LabryinthDirection,
    CorrectOrDelete
  },
  props: {
    slideData: {
      type: Object,
      required: true
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  emits: ['image', 'correct', 'path'],
  methods: {
    setImage(image) {
      this.$emit('image', image)
    },
    setCorrect(index) {
      this.$emit('correct', index)
    },
    updatePath(index, value) {
      this.$emit('path', index, value)
    }
  }
}

</script>
<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

h4 {
  font-weight: 600;
}

.labryinth-container{
  position: relative;
  margin-bottom: rem(20)
}

.labryrinth-toggle{
  top: rem(50)
}

</style>
