<template>
  <div class="ok-or-not-container">


    <div class="is-ok-option" @click="set(true)">
      <Checkbox :enabled="isOk" :radio="true"/>
      {{ textOk ?? $t('DIAS.NETIQUETTE.IS_OK') }}
    </div>
    <div class="is-ok-option" @click="set(false)">
      <Checkbox :enabled="!isOk" :radio="true"/>
      {{ textNotOk ?? $t('DIAS.NETIQUETTE.IS_NOT_OK') }}
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/elements/basic/Checkbox'


export default {
  name: 'OkOrNotToggle',
  components: {
    Checkbox
  },
  props: {
    textOk: {
      type: String,
      default: null
    },
    textNotOk: {
      type: String,
      default: null
    },
    startValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle'],
  data() {
    return {
      isOk: !!this.startValue
    }
  },
  methods: {
    set(isOk) {
      this.isOk = isOk
      this.$emit('toggle', isOk)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.is-ok-option {
  display: flex;
  gap: rem(20);
  margin-top: rem(10);
  margin-bottom: rem(10);
  cursor: pointer;
  align-items: center;
}

.ok-or-not-container{
  margin: rem(30) rem(20);
}


</style>
