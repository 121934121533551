<template>
  <LargeInputField
    :field-title="$t('DIAS.POLL.STATEMENT')"
    :field-name="`statement`"
    :rules="'required'"
    :type="'text'"
    :placeholder="$t('DIAS.POLL.STATEMENT_PLACEHOLDER')"
    :large="true"
    :value="initialData ? initialData.contentQuestionJson.statement : ''"
    class="form-element"
  />
  <InputField
    :field-title="$t('DIAS.POLL.POSITIVE')"
    :field-name="`positiveAnswer`"
    :rules="'required'"
    :type="'text'"
    :on-update="(value) => answerUpdate(0,value)"
    :value="initialData ? initialData.contentQuestionJson.answers[0].answer : ''"
    :placeholder="$t('DIAS.POLL.POSITIVE_PLACEHOLDER')"
    :max-count="maxCharacters"
    class="form-element"
  >
    <!-- <template #right-side-content>
      <div class="character-count-display"> {{ characterCounts[0] + "/" + maxCharacters }} </div>
    </template> -->
  </InputField>
  <InputField
    :field-title="$t('DIAS.POLL.NEGATIVE')"
    :field-name="`negativeAnswer`"
    :rules="'required'"
    :type="'text'"
    :on-update="(value) => answerUpdate(1,value)"
    :value="initialData ? initialData.contentQuestionJson.answers[1].answer : ''"
    :placeholder="$t('DIAS.POLL.NEGATIVE_PLACEHOLDER')"
    :max-count="maxCharacters"
    class="form-element"
  >
    <!-- <template #right-side-content>
      <div class="character-count-display"> {{ characterCounts[0] + "/" + maxCharacters }} </div>
    </template> -->
  </InputField>

  <InputField
    :field-title="$t('DIAS.POLL.NEUTRAL')"
    :field-name="`neutralAnswer`"
    :rules="'required'"
    :type="'text'"
    :on-update="(value) => answerUpdate(2,value)"
    :value="initialData ? initialData.contentQuestionJson.answers[2].answer : ''"
    :placeholder="$t('DIAS.POLL.NEUTRAL_PLACEHOLDER')"
    :max-count="maxCharacters"
    class="form-element"
  >
    <!-- <template #right-side-content>
      <div class="character-count-display"> {{ characterCounts[0] + "/" + maxCharacters }} </div>
    </template> -->
  </InputField>

</template>

<script>

import InputField from '@/components/yo-form/InputField'
import LargeInputField from '../../../yo-form/LargeInputField.vue'


export default {
  name: 'AnswerPoll',
  components: {
    InputField,
    LargeInputField
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    showErrors: {
      type: Boolean,
      default: false
    }
  },
  emits: ['data', 'verify'],
  data() {
    return {
      maxCharacters: 25,
      characterCounts: [0, 0, 0]
    }
  },
  methods: {
    answerUpdate(index, answer) {
      this.characterCounts[index] = answer.length
      this.emitData()
    },
    emitData() {
      this.$emit('data', {})
      this.$emit('verify', this.characterCounts.every(x => x <= this.maxCharacters))
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.character-count-display{
  //position: absolute;
  //right: 0px;
  color: #7A95B2;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: right;
  margin-top: 4px;
}

</style>
