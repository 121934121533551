<template>
  <div class="labryinth-path-container">
    <div class="path-wrapper">
      <div v-for="index in maxLength" :key="index" class="shadow">
        <div v-if="index > path.length" class="empty square"/>
        <inline-svg v-else
                    :class="'arrow-'+path[index-1]"
                    class="empty square inverted pressable"
                    :src="require('@/assets/icons/btn_arrowleft.svg')"
                    @click="removeStep(index-1)"/>
      </div>
    </div>
    <div class="path-wrapper">
      <inline-svg v-for="direction in directions"
                  :key="direction"
                  :class="'arrow-'+direction"
                  class="filled square pressable"
                  :src="require('@/assets/icons/btn_arrowleft.svg')"
                  @click="addStep(direction)"/>
    </div>
  </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg'

export default {
  name: 'LabryinthDirection',
  components: {
    InlineSvg
  },
  props: {
    maxLength: {
      type: Number,
      default: 8
    },
    path: {
      type: Array,
      default: () => []
    }
  },
  emits: ['path'],
  data() {
    return {
      directions: ['down', 'right', 'up', 'left'],
    }
  },
  methods: {
    addStep(direction) {
      if (this.path.length < this.maxLength) {
        this.$emit('path', this.path.concat(direction))
      }
    },
    removeStep(index) {
      if (index >= this.path.length) {
        return
      }
      const newPath = [...this.path]
      newPath.splice(index, 1)
      this.$emit('path', newPath)
    },

  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.labryinth-path-container{
  display: flex;
  flex-direction: column;
}

.path-wrapper{
  display: flex;
  flex-wrap: nowrap;
  gap: rem(5);
  margin: rem(10) rem(0);
}

.square{
  width: rem(50);
  height: rem(50);
  border-radius: rem(10);
}

.empty{
  background-color: #DAE1EF;
}

.filled{
  background-color: #c7c9d4;
}

.pressable{
  cursor: pointer;
}

.shadow{

}

.arrow-down{
  :deep #arrow{
  transform: translate(12.0px, 12.0px) rotate(-90.0deg) translate(-12.0px, -12.0px);
  }
}
.arrow-right{
  :deep #arrow{
  transform: translate(12.0px, 12.0px) scale(-1, 1) translate(-12.0px, -12.0px);
  }
}
.arrow-up{
  :deep #arrow{
  transform: translate(12.0px, 12.0px) rotate(90.0deg) translate(-12.0px, -12.0px);
  }
}
.arrow-left{
}

// :deep #Icon/Arrow/Maze/Right-{
//         fill: blue;
// }

.inverted{
 :deep #background{
   fill:white;
 }

  :deep #arrow{
   fill:#373B52;
 }
}

</style>
