<template>

  <Dropdown id="question-type"
            :field-title="$t('DIAS.TRUE_OR_FALSE.QUESTION_TYPE')"
            :dropdown-options="types"
            :placeholder="''"
            :field-name="'selectedQuestionType'"
            :selected-option="selectedType"
            :callback="(value) => setSelected(value)"
            :disabled="initialData !== null"
            :api-error-text="''"
            class="form-element"/>
  <Article v-if="selectedType.id === TYPES_TRUE_OR_FALSE.ARTICLE"
           :initial-data="initialData"
           :show-errors="showErrors"
           @data="onNewData"
           @verify="onVerify"/>
  <Phishing v-if="selectedType.id === TYPES_TRUE_OR_FALSE.REAL_OR_PHISHING"
            :initial-data="initialData"
            :show-errors="showErrors"
            @data="onNewData"
            @verify="onVerify"/>
  <Netiquette v-if="selectedType.id === TYPES_TRUE_OR_FALSE.NETIQUETTE"
              :initial-data="initialData"
              :show-errors="showErrors"
              @data="onNewData"
              @verify="onVerify"/>

</template>

<script>

import nl from '@/utils/language/nl.json'
import Dropdown from '@/components/yo-form/Dropdown.vue'
import Article from '../trueOrFalseTypes/Article.vue'
import Netiquette from '../trueOrFalseTypes/Netiquette.vue'
import Phishing from '../trueOrFalseTypes/Phishing.vue'
import { TYPES_TRUE_OR_FALSE } from '@/utils/helpers/mission/SelectAnswerConsts'
export default {
  name: 'AnswerTrueOrFalse',
  components: {
    Dropdown,
    Article,
    Netiquette,
    Phishing
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    showErrors: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['data', 'verify'],
  data() {
    const types = [
      {
        id: TYPES_TRUE_OR_FALSE.ARTICLE,
        name: nl.DIAS.TRUE_OR_FALSE.TYPES.ARTICLE
      },
      {
        id: TYPES_TRUE_OR_FALSE.REAL_OR_PHISHING,
        name: nl.DIAS.TRUE_OR_FALSE.TYPES.PHISHING
      },
      {
        id: TYPES_TRUE_OR_FALSE.NETIQUETTE,
        name: nl.DIAS.TRUE_OR_FALSE.TYPES.NETIQUETTE
      },
    ]
    const selectedType = this.initialData ? types.find(x => x.id === this.initialData.missionSlideTypeId) : types[0]
    return {
      types,
      selectedType,
      TYPES_TRUE_OR_FALSE,
    }
  },
  methods: {
    setSelected(type) {
      this.selectedType = type
    },
    onNewData(data) {
      this.$emit('data', data)
    },
    onVerify(isValid) {
      this.$emit('verify', isValid)
    }
  }
}
</script>

<style>

</style>
