<template>
  <FormWrapper :send-form-data="validateData" class="page-form">
    <template #form-content>

      <h3>{{ $t('DIAS.TEXT') }} {{ $t('DIA') }}</h3>

      <InputField :field-title="$t('DIAS.TITLE')" :field-name="'title'" :rules="'required'" :type="'text'"
        :value="initialData ? initialData.title : ''" :placeholder="$t('DIAS.TITLE_PLACEHOLDER')"
        :cy-selector="'name-input'" class="form-element" />
      <div class="double-column">
        <RichTextField :field-title="$t('DIAS.TEXT')" :placeholder="$t('DIAS.TEXT_PLACEHOLDER')"
          :input-callback="handleTextInput" :value="textInput" :error-text="textInputError">
        </RichTextField>

        <InformationBox :display-icon="true" :custom-class="'dia-info'">
          <template #text>
            <div class="info-text">
              {{ $t('DIAS.TEXT_INFO') }}
            </div>
          </template>
        </InformationBox>
      </div>
      <InputField v-show="!hideBits"
        :field-title="$t('DIAS.BITS')" field-name="bitsAwarded" rules="required" type="number"
        :value="hideBits ? '0' : initialData ? initialData.bitsAwarded : ''" :placeholder="$t('DIAS.BITS_PLACEHOLDER')"
        cy-selector="bits-input" class="form-element" :input-icon="bitsIcon" />
    </template>

    <template #button-submit>
      <button type="submit" class="pink-button submit-button">
        {{ $t('CREATE_MISSION.SAVE_AND_CONTINUE') }}
      </button>
    </template>
  </FormWrapper>
</template>

<script>
/* eslint-disable no-useless-escape */

import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { CREATE_DIA } from '@/store/modules/cms/missions/actions'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import { ref } from '@vue/reactivity'
import InformationBox from '@/components/elements/InformationBox.vue'
import bitsIcon from '@/assets/icons/icn_bits_transparent.svg'

export default {
  name: 'Details',
  components: {
    InputField,
    FormWrapper,
    RichTextField,
    InformationBox
  },
  props: {
    id: {
      type: String,
      required: true
    },
    initialData: { //filled if editing
      type: Object,
      default: null
    },
    hideBits: {
      type: Boolean,
      default: false
    }
  },
  setup(proxy) {
    const store = useStore()
    const router = useRouter()
    const slideId = proxy.initialData ? proxy.initialData.id : null

    const textInput = ref(proxy.initialData ? rejoin(proxy.initialData.contentQuestionJson.content) : '')
    const textInputError = ref(null)

    function handleTextInput(text) {
      textInput.value = text
    }

    function splitText(text) {
      const regexStr = '<p><\/p>|<br><br>'
      const removeCertainFlags = '<p>|<\/p>|<br>|<\/br>'
      const regExClean = new RegExp(removeCertainFlags, 'gm')
      const regEx = new RegExp(regexStr)
      return text.split(regEx).map(x => x.replace(regExClean, '').trim()).filter(x => x.trim() !== '')
    }

    function rejoin(text) {
      const start = '<p>'
      const middle = '<p><\/p>'
      const end = '<p>'

      return start + text.join(middle) + end
    }


    function validateData(data) {
      if (textInput.value === '') {
        setErrorTextInput()
        return
      } else {
        clearErrorTextInput()
      }

      data.missionId = proxy.id
      data.content = splitText(textInput.value)
      store.dispatch(CREATE_DIA, { data, type: 'text', slideId }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
        }
      })
    }

    function setErrorTextInput() {
      textInputError.value = 'De tekst is verplicht.'
    }

    function clearErrorTextInput() {
      textInputError.value = ''
    }

    return {
      handleTextInput,
      validateData,
      rejoin,
      textInput,
      textInputError,
      bitsIcon
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.form-element {
  h3 {
    color: #373B52;
  }

  .form-input {
    padding: rem(10) rem(10) rem(10) rem(10);
  }
}

h3 {
  margin-bottom: rem(20);
}

.double-column {
  display: flex;
  gap: rem(20);
}
</style>
