<template>
  <InputField :field-title="$t('DIAS.ARTICLE.TITLE')"
              :field-name="'articleTitle'"
              :rules="'required'"
              :type="'text'"
              :placeholder="$t('DIAS.ARTICLE.TITLE_PLACEHOLDER')"
              :cy-selector="'name-input'"
              :api-error-text="''"
              :value="initialData ? initialData.contentQuestionJson.articleTitle : null"
              :bind-value="true"
              class="form-element"/>


  <LargeInputField :field-title="$t('DIAS.ARTICLE.CONTENT')"
                   :field-name="'articleContent'"
                   :rules="'required'"
                   :type="'text'"
                   :placeholder="$t('DIAS.ARTICLE.CONTENT_PLACEHOLDER')"
                   :value="initialData ? initialData.contentQuestionJson.articleContent : null"
                   :bind-value="true"
                   :cy-selector="'name-input'"
                   :api-error-text="''"
                   class="form-element"/>
  <h4> {{ $t('DIAS.ARTICLE.IMAGE') }} </h4>
  <ImageInput
    :media-files="initialData ? initialData.mediaFiles : null"
    :start-file="image"
    :error-text="imageError"
    @file="(file) => setImage(file)"
  />
  <InputField :field-title="$t('DIAS.ARTICLE.POSITIVE_ANSWER')"
              :field-name="'answers[0]'"
              :rules="'required'"
              :type="'text'"
              :value="initialData ? initialData.contentQuestionJson.answers[0] : null"
              :bind-value="true"
              :placeholder="$t('DIAS.ARTICLE.POSITIVE_ANSWER_PLACEHOLDER')"
              :cy-selector="'name-input'"
              :api-error-text="''"
              :on-update="(value) => answerUpdate(0,value)"

              class="form-element">
    <template #right-side-content>
      <correct-or-delete :show-remove="false"
                         class="right-content"
                         :start-correct="isPositive"
                         :single="true"
                         @correct="(correct) => setPositive(correct)"
      />
      <div class="character-count-display"> {{ characterCounts[0] + "/" + maxCharacters }} </div>

    </template>
  </InputField>

  <InputField :field-title="$t('DIAS.ARTICLE.NEGATIVE_ANSWER')"
              :field-name="'answers[1]'"
              :rules="'required'"
              :type="'text'"
              :placeholder="$t('DIAS.ARTICLE.NEGATIVE_ANSWER_PLACEHOLDER')"
              :cy-selector="'name-input'"
              :on-update="(value) => answerUpdate(1,value)"
              :value="initialData ? initialData.contentQuestionJson.answers[1] : null"
              :bind-value="true"
              :api-error-text="''"
              class="form-element">
    <template #right-side-content>
      <correct-or-delete :show-remove="false"
                         :start-correct="!isPositive"
                         :single="true"

                         @correct="(correct) => setPositive(!correct)"

      />
      <div class="character-count-display"> {{ characterCounts[1] + "/" + maxCharacters }} </div>
    </template>
  </InputField>
  <FeedbackInput
    :initial-data="initialData"
    @data="feedbackData"
  />

</template>

<script>

import InputField from '@/components/yo-form/InputField'
import LargeInputField from '@/components/yo-form/LargeInputField'
import CorrectOrDelete from '../../../elements/inputFields/CorrectOrDelete.vue'
import ImageInput from '../../../elements/inputFields/ImageInput.vue'
import FeedbackInput from '@/components/partials/missions/FeedbackInput'

import { TYPES_TRUE_OR_FALSE } from '@/utils/helpers/mission/SelectAnswerConsts.js'

export default {
  name: 'Article',
  components: {
    InputField,
    LargeInputField,
    CorrectOrDelete,
    ImageInput,
    FeedbackInput
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    showErrors: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['data', 'verify'],
  data() {
    return {
      maxCharacters: 30,
      characterCounts: [0, 0],
      image: this.initialData ? this.initialData.contentQuestionJson.mediaFile.url : null,
      isPositive: this.initialData ? this.initialData.contentAnswerJson.correctAnswer === 0 : false,
      imageError: '',
      correctVideoId: this.initialData && this.initialData.correctVideo ? this.initialData.correctVideo.Id : null,
      incorrectVideoId: this.initialData && this.initialData.incorrectVideo ? this.initialData.incorrectVideo.Id : null,
    }
  },
  mounted() {
    this.onUpdate()
  },
  methods: {
    feedbackData(data) {
      this.correctVideoId = data.correctVideo ? data.correctVideo.id : null
      this.incorrectVideoId = data.wrongVideo ? data.wrongVideo.id : null
      this.onUpdate()
    },
    setImage(image) {
      this.image = image
      this.onUpdate()
    },
    setPositive(isPositive) {
      this.isPositive = isPositive
      this.onUpdate()
    },
    answerUpdate(index, answer) {
      this.characterCounts[index] = answer.length
      this.onUpdate()
    },
    onUpdate() {
      this.$emit('data', {
        slideType: TYPES_TRUE_OR_FALSE.ARTICLE,
        mediaFile: this.image,
        correctAnswer: this.isPositive ? 0 : 1,
        correctVideoId: this.correctVideoId,
        incorrectVideoId: this.incorrectVideoId,
      })
      this.$emit('verify', this.isValid())
    },
    isValid() {
      if (this.image === null) {
        this.imageError = 'Voer de artikelomslag in.'
      } else {
        this.imageError = ''
      }

      return this.characterCounts.every(x => x <= this.maxCharacters) && this.image !== null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.character-count-display{
  position: absolute;
  right: 0px;
  color: grey;
}

</style>
