<template>
  <FormWrapper :send-form-data="validateData" class="page-form">
    <template #form-content>
      <h3>{{ $t('DIAS.DETAILS') }} </h3>
      <InputField :field-title="$t('DIAS.TITLE')" :field-name="'title'" :rules="'required'" :type="'text'"
        :placeholder="$t('DIAS.TITLE_PLACEHOLDER')" :cy-selector="'name-input'"
        :value="initialData ? initialData.title : ''" class="form-element" />
      <RichTextField :field-title="$t('DIAS.DESCRIPTION')" :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
        :input-callback="(value) => updateDescription(value)" :value="initialData ? initialData.description : ''"
        :error-text="descriptionErrorMessage" :max-length="992" />
      <LargeInputField v-if="showTip" :field-title="$t('DIAS.TIP')" :field-name="'tip'"
        :inline-subtitle="`(${$t('OPTIONAL')})`" :type="'text'" :rules="'optional'"
        :placeholder="$t('DIAS.TIP_PLACEHOLDER')" :cy-selector="'name-input'"
        :value="initialData ? initialData.tip : null" :max-length="255" class="form-element" />
      <Dropdown id="group" :field-title="$t('DIAS.TYPE_ANSWER')" :dropdown-options="options" :placeholder="''"
        :field-name="'group'" :selected-option="selectedOption" :callback="(value) => changeOption(value)"
        :api-error-text="''" :disabled="initialData !== null" class="form-element" />

      <AnswerText v-if="selectedOption.id === 0" :initial-data="initialData" :show-errors="showErrors" @data="newData"
        @verify="setValid" />
      <AnswerImage v-if="selectedOption.id === 1" :initial-data="initialData" @data="newData" @verify="setValid" />
      <AnswerPoll v-if="selectedOption.id === 2" :initial-data="initialData" :show-errors="showErrors" @data="newData"
        @verify="setValid" />
      <AnswerTrueOrFalse v-if="selectedOption.id === 3" :initial-data="initialData" :show-errors="showErrors"
        @data="newData" @verify="setValid" />
      <AnswerLabryinth v-if="selectedOption.id === 4" :initial-data="initialData" :show-errors="showErrors"
        @data="newData" @verify="setValid" />

      <InputField v-show="!hideBits" :field-title="$t('DIAS.BITS')" field-name="bitsAwarded" rules="required" type="number"
        :value="hideBits ? '0' : initialData ? initialData.bitsAwarded : ''" :placeholder="$t('DIAS.BITS_PLACEHOLDER')"
        cy-selector="bits-input" class="form-element" :input-icon="bitsIcon" />

      <div class="separator-line"></div>

      <FeedbackInput v-if="REQUIRES_FEEDBACK.some(x => x === selectedOption.id)" :initial-data="initialData"
        @data="feedbackData" />
    </template>

    <template #button-submit>
      <button type="submit" class="pink-button submit-button" @click="verifyFeedbackField">
        {{ $t('CREATE_MISSION.SAVE_AND_CONTINUE') }}
      </button>
    </template>
  </FormWrapper>
</template>

<script>
import { ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import InputField from '@/components/yo-form/InputField'
import LargeInputField from '@/components/yo-form/LargeInputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import {
  CREATE_DIA,
  GET_MISSION_DETAILS
} from '@/store/modules/cms/missions/actions'
import AnswerImage from '@/components/partials/missions/selectAnswer/AnswerImage'
import AnswerLabryinth from '@/components/partials/missions/selectAnswer/AnswerLabryinth'
import AnswerPoll from '@/components/partials/missions/selectAnswer/AnswerPoll'
import AnswerText from '@/components/partials/missions/selectAnswer/AnswerText'
import AnswerTrueOrFalse from '@/components/partials/missions/selectAnswer/AnswerTrueOrFalse'
import Dropdown from '@/components/yo-form/Dropdown'

import nl from '@/utils/language/nl.json'
import RichTextField from '../../../elements/RichTextEditor/RichTextField.vue'
import { TYPES_TRUE_OR_FALSE, slideTypeMapper } from '@/utils/helpers/mission/SelectAnswerConsts.js'
import { mapGetters } from 'vuex'
import FeedbackInput from '@/components/partials/missions/FeedbackInput'
import bitsIcon from '@/assets/icons/icn_bits_transparent.svg'

export default {
  name: 'Details',
  components: {
    InputField,
    FormWrapper,
    AnswerImage,
    AnswerLabryinth,
    AnswerPoll,
    AnswerText,
    AnswerTrueOrFalse,
    Dropdown,
    RichTextField,
    LargeInputField,
    FeedbackInput
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    originalSlideId: {
      type: String,
      default: null
    },
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    hideBits: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const slideData = {}
    const isValid = true
    const showErrors = false
    const options = [
      {
        id: 0,
        name: nl.DIAS.SELECT_ANSWER.TEXT
      },
      {
        id: 1,
        name: nl.DIAS.SELECT_ANSWER.IMAGE
      },
      {
        id: 2,
        name: nl.DIAS.SELECT_ANSWER.POLL
      },
      {
        id: 3,
        name: nl.DIAS.SELECT_ANSWER.TRUE_OR_FALSE
      },
      {
        id: 4,
        name: nl.DIAS.SELECT_ANSWER.LABRYINTH
      },
    ]

    const selectedOption = ref(options[this.initialData ? slideTypeMapper[this.initialData.missionSlideTypeId] : 0])
    const REQUIRES_FEEDBACK = [0, 1, 2, 4]
    return {
      options,
      selectedOption,
      slideData,
      isValid,
      showErrors,
      title: this.initialData ? this.initialData.title : '',
      description: this.initialData ? this.initialData.description : '',
      descriptionErrorMessage: '',
      REQUIRES_FEEDBACK,
      slideTypeMapper,
      slideId: this.initialData ? this.initialData.id : null,
      bitsIcon
    }
  },
  computed: {
    ...mapGetters({
      missionDetails: 'getMissionDetails'
    }),
    showTip() { return this.missionDetails && this.missionDetails.missionCategoryId === 3 }
  },
  created() {
    this.$store.dispatch(GET_MISSION_DETAILS, this.id)
  },
  methods: {
    feedbackData(data) {
      this.slideData.correctVideoId = data.correctVideo ? data.correctVideo.id : null
      this.slideData.wrongVideoId = data.wrongVideo ? data.wrongVideo.id : null
    },
    changeOption(choice) {
      this.slideData = {}
      this.showErrors = false
      this.isValid = false
      this.selectedOption = choice
    },
    newData(data) {
      this.slideData = data
    },
    setValid(valid) {
      this.isValid = valid
    },
    updateDescription(data) {
      this.description = data
    },
    verifyFeedbackField() {
      if (this.description === '') {
        this.descriptionErrorMessage = 'De omschrijving is verplicht.'
      } else {
        this.descriptionErrorMessage = ''
      }
    },
    validateData(data) {
      if (this.descriptionErrorMessage !== '') {
        return
      }
      data.description = this.description
      if (!this.isValid) {
        this.showErrors = true
        return
      }
      data.missionId = this.id
      switch (this.selectedOption.id) {
        case 0: //text answer
          this.dispatchTextAnswer(data)
          break
        case 1: //image answer
          this.dispatchImageAnswer(data)
          break
        case 2: //poll answer
          this.dispatchPollAnswer(data)
          break
        case 3: //true of false answer
          switch (this.slideData.slideType) {
            case TYPES_TRUE_OR_FALSE.REAL_OR_PHISHING: // real or phishing
              this.dispatchPhishingAnswer(data)
              break
            case TYPES_TRUE_OR_FALSE.NETIQUETTE:
              this.dispatchNetiquetteAnswer(data)
              break
            case TYPES_TRUE_OR_FALSE.ARTICLE:
              this.dispatchArticleAnswer(data)
              break
          }
          break
        case 4: // labryinth
          this.dispatchLabryinthAnswer(data)
          break
        default:
          console.error('No supported API call yet!!')
          break
      }
    },
    dispatchTextAnswer(data) {
      const finalData = {}
      finalData.correctVideoId = this.slideData.correctVideoId
      finalData.incorrectVideoId = this.slideData.wrongVideoId
      finalData.description = data.description
      finalData.bitsAwarded = data.bitsAwarded
      finalData.feedback = data.feedback
      finalData.group = data.group
      finalData.missionId = data.missionId
      finalData.title = data.title
      finalData.answers = this.slideData.answers
      finalData.tip = data.tip
      let correctCount = 0
      this.slideData.correct.forEach((x) => {
        if (x) {
          correctCount++
        }
      })
      if (correctCount === 1) {
        finalData.missionSlideTypeId = this.initialData ? 7 : null

        const correctIndex = this.slideData.correct.findIndex(x => x) //find where correct
        finalData.correctAnswer = correctIndex + 1 //just the way the database wants it.
        this.$store.dispatch(CREATE_DIA, { data: finalData, type: 'single-choice', slideId: this.slideId }).then(response => {
          if (response && (response.status === 201 || response.status === 204)) {
            this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
          }
        })
      } else if (correctCount > 1) {
        finalData.missionSlideTypeId = this.initialData ? 8 : null
        for (let i = 0, j = 0; i < this.slideData.correct.length; i++) {
          if (this.slideData.correct[i]) {
            finalData[`correctAnswer[${j}]`] = i + 1 //just the way the database wants it.
            j++
          }
        }

        this.$store.dispatch(CREATE_DIA, { data: finalData, type: 'multiple-choice', slideId: this.slideId }).then(response => {
          if (response && (response.status === 201 || response.status === 204)) {
            this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
          }
        })
      }
    },
    dispatchImageAnswer(data) {
      data.correctVideoId = this.slideData.correctVideoId
      data.incorrectVideoId = this.slideData.wrongVideoId
      data.missionSlideTypeId = this.initialData ? this.initialData.missionSlideTypeId : null
      data.answers = []
      let correctCount = 0
      this.slideData.forEach((slide) => {
        data.answers.push(slide.file)
        if (slide && slide.correct) {
          correctCount++
        }
      })

      if (correctCount === 1) {
        data.missionSlideTypeId = this.initialData ? 9 : null

        const correctIndex = this.slideData.findIndex(x => x.correct) //find where correct
        data.correctAnswer = correctIndex + 1//just the way the database wants it.
        this.$store.dispatch(CREATE_DIA, { data, type: 'single-choice-picture', slideId: this.slideId })
          .then(response => {
            if (response && (response.status === 201 || response.status === 204)) {
              this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
            }
          })
      } else if (correctCount > 1) {
        data.missionSlideTypeId = this.initialData ? 10 : null
        for (let i = 0, j = 0; i < this.slideData.length; i++) {
          if (this.slideData[i].correct) {
            data[`correctAnswer[${j}]`] = i + 1 //just the way the database wants it.
            j++
          }
        }

        this.$store.dispatch(CREATE_DIA, { data, type: 'multiple-choice-picture', slideId: this.slideId })
          .then(response => {
            if (response && (response.status === 201 || response.status === 204)) {
              this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
            }
          })
      }
    },
    dispatchPollAnswer(data) {
      data.answers = [
        data.positiveAnswer, data.negativeAnswer, data.neutralAnswer
      ]
      data.bitsAwarded = 25
      data.correctVideoId = this.slideData.correctVideoId
      data.incorrectVideoId = this.slideData.wrongVideoId

      this.$store.dispatch(CREATE_DIA, { data, type: 'poll', slideId: this.slideId }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
        }
      })
    },
    dispatchLabryinthAnswer(data) {
      data.correctVideoId = this.slideData.correctVideoId
      data.incorrectVideoId = this.slideData.wrongVideoId
      const subSlides = this.slideData.slides.map(slide => {
        const mappedAnswers = slide.answers.map(x => x.directions.map(value => value))
        const correctIndex = slide.answers.findIndex(x => x.correct)

        const subSlideObject = {
          bitsEarned: 10,
          answers: mappedAnswers,
          correctAnswer: correctIndex
        }

        return subSlideObject
      })

      const subSlideImages = this.slideData.slides.map(slide => slide.image)
      data.subSlides = subSlides
      data.subSlideImages = subSlideImages
      this.$store.dispatch(CREATE_DIA, { data, type: 'maze', slideId: this.slideId }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
        }
      })
    },
    dispatchPhishingAnswer(data) {
      const emails = this.slideData.emails

      const subSlides = emails.map(email => {
        return {
          bitsEarned: 10,
          sender: email.sender,
          emailorPhoneNumber: email.emailOrPhoneNumber,
          topic: email.topic,
          message: email.message,
          correctAnswer: email.isReal ? 1 : 0,
          feedback: email.feedback
        }
      })
      data.subSlides = subSlides

      this.$store.dispatch(CREATE_DIA, { data, type: 'real-or-phishing', slideId: this.slideId })
        .then(response => {
          if (response && (response.status === 201 || response.status === 204)) {
            this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
          }
        })
    },
    dispatchArticleAnswer(data) {
      data.mediaFile = this.slideData.mediaFile
      data.correctAnswer = this.slideData.correctAnswer
      data.correctVideoId = this.slideData.correctVideoId
      data.incorrectVideoId = this.slideData.incorrectVideoId
      this.$store.dispatch(CREATE_DIA, { data, type: 'article', slideId: this.slideId }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
        }
      })
    },
    dispatchNetiquetteAnswer(data) {
      const slides = this.slideData.questions
      const subSlides = slides.map(slide => convertSlideToAPIData(slide, slide.type, slide.isOk))
      const subSlideImages = slides.map(slide => slide.image ?? null)
      data.subSlides = subSlides
      data.subSlideImages = subSlideImages
      this.$store.dispatch(CREATE_DIA, { data, type: 'netiquette', slideId: this.slideId }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
        }
      })

      function convertSlideToAPIData(slide, type, isOk) {
        const slideData = {
          correctAnswer: isOk ? 1 : 0,
          bitsEarned: 10,
          netiquetteTypeId: type.id,
          feedback: slide.feedback
        }
        switch (type.id) {
          case 1: {
            //chat
            const labelledMessages = slide.messages.map((message, index) => {
              return {
                isMe: slide.senders[index],
                message: message
              }
            })
            slideData.messages = labelledMessages
          }
            break
          case 2:
            //comment
            slideData.messages = slide.comments
            break
          case 3:
            //friend request
            slideData.message = slide.message
            break
        }
        return slideData
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.border-line {
  border-top: 2px solid #DDE3F0;
  margin-bottom: rem(20);
  margin-top: rem(20);
}

.form-element {
  margin-top: rem(20);

  h3 {
    color: #373B52;
  }

  .form-input {
    padding: rem(10) rem(10) rem(10) rem(10);
  }
}

h3 {
  margin-bottom: rem(20);
}

.dropdown {
  margin-bottom: rem(20);
}

.separator-line {
  margin-top: 32px;
  width: 100%;
  height: 1px;
  background-color: #DAE1EF;
  margin-bottom: 24px;
}
</style>
