<template>
  <div class="select-video-wrapper">
    <InputField :id="`dropdown-toggle-${id}`"
                :field-title="title ?? $t('VIDEO_SELECT.TITLE')"
                :field-name="'searchText'"
                :type="'text'"
                :rules="'optional'"
                :bind-value="true"
                :placeholder="selected ? selected.name : $t('VIDEO_SELECT.PLACEHOLDER')"
                :value="currentSearch"
                :debounce="0"
                :on-update="updateSearch"
                error-icon-offset="2rem"
                autocomplete="off"
                @click="toggleDropdown()">
    </InputField>

    <div v-if="selected" class="selected-preview">
      <VimeoPlayer
        :video-url="selected.originalVideoUrl"
        :player-height="200"
        :player-width="400"
      />
    </div>
    <div v-show="isFocused" :id="`option-list-${id}`" class="found-video-list">
      <div v-for="video in searchResults.items"
           :key="video.id"
           class="video-option"
           @click="selectVideo(video)">
        <img :src="video.thumbnailUrl" class="video-option-thumbnail"/>
        <a target="_blank" class="video-option-text"> {{ video.name }}</a>
        <img class="video-option-link" src="@/assets/icons/icn_externallink.svg" @click="openVideo(video.originalVideoUrl)"/>
      </div>
      <div v-if="searchResults.totalItems === 0" class="no-videos-found-text"> {{ $t('VIDEO_SELECT.NO_VIDEOS_FOUND') }}</div>
    </div>
  </div>
</template>

<script>

import InputField from '@/components/yo-form/InputField.vue'
import { GET_MISSION_VIDEOS, GET_MISSION_VIDEO } from '@/store/modules/cms/actions'
import { mapGetters } from 'vuex'
import { addListener, removeListener } from '@/utils/helpers/listenerHelper'
import VimeoPlayer from '../video/VimeoPlayer.vue'

export default {
  name: 'SelectVideo',
  components: {
    InputField,
    VimeoPlayer
  },
  props: {
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Video Input'
    },
    initialSelection: {
      type: [Object, Number],
      default: null
    }
  },
  emits: ['selected'],
  data() {
    let selected = null
    if (typeof this.initialSelection === 'number') {
      this.loadVideo(this.initialSelection)
    } else {
      selected = this.initialSelection
      if (this.initialSelection) {
        selected.originalVideoUrl = this.initialSelection.videoUrl
        this.$emit('selected', selected)
      }
    }

    return {
      timeId: null,
      currentSearch: null,
      selected,
      isFocused: false,
    }
  },
  computed: {
    ...mapGetters({
      searchResults: 'getMissionVideos'
    }),
  },
  methods: {
    updateSearch(newSearch) {
      if (!this.isFocused) {
        this.toggleDropdown()
      }
      this.currentSearch = newSearch
      this.bounceSearch(newSearch)
    },
    loadVideo(id) {
      this.$store.dispatch(GET_MISSION_VIDEO, id).then(response => {
        this.selected = response.data
        this.selected.originalVideoUrl = this.selected.videoUrl
        console.log('chosen', this.selected)
        this.$emit('selected', this.selected)
      })
    },
    bounceSearch(searchTerm) {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(() => this.search(searchTerm), 500)
    },
    search(value) {
      const payload = {
        searchQuery: value,
        page: 1,
        limit: 15,
      }
      this.$store.dispatch(GET_MISSION_VIDEOS, payload)
    },
    selectVideo(video) {
      this.selected = video
      this.$emit('selected', video)
      this.currentSearch = null
      if (this.isFocused) {
        this.toggleDropdown()
      }
    },
    openVideo(link) {
      window.open(link, '_blank').focus()
    },
    toggleDropdown() {
      this.isFocused = !this.isFocused

      if (this.isFocused) {
        this.search(this.currentSearch)
      }
      // If the dropdown option list is displayed, the click event should be listened to.
      this.isFocused ? addListener('click', this.clickEvent) : removeListener('click', this.clickEvent)
    },
    clickEvent(event) {
      if (!this.eventContainsElement(event, `option-list-${this.id}`) && !this.eventContainsElement(event, `dropdown-toggle-${this.id}`)) {
        this.toggleDropdown()
      }
    },
    eventContainsElement(event, elementId) {
      return document.getElementById(elementId).contains(event.target)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
$text-color: var(--blue_dark_01);
$background-color: white;
$border-color: #F4F6FB;


.select-video-wrapper{
  position: relative;
  margin-top: 16px;
}

.selected-preview{
  display: flex;
  gap: rem(20);
  align-items: center;
  margin-top: rem(20);
}

.preview-thumbnail{
  width: rem(20);
}

.preview-title{
  height: rem(40);
}

.found-video-list{
  @include position-elevation(absolute, $top: 80px, $right: 0, $left: 0, $elevation: 15);
  border-radius: rem(10);
  background-color: $background-color;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
}

.video-option{
  padding: rem(17) rem(20);
  color: $text-color;
  font-size: rem(18);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
  display: flex;
  justify-content: space-between;
  gap:rem(20);
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: rem(1) solid $border-color;
  }

  &:hover{
    cursor: pointer;
    background-color: rgba(232, 232, 243, 0.226);
  }
}



.video-option{

}

.video-option-thumbnail{
  width: 64px;
  height: 64px;
  border-radius: rem(5);
  object-fit: contain;
}


.video-option-link{
  width: 22px;
  height: 22px;
}

.video-option-text{
  flex-grow: 1;
  text-align: left;
}

.no-videos-found-text {
  color: $text-color;
  padding: rem(17) rem(20);
}
</style>
