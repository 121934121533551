<template>
  <div v-for="(email,index) in emails"
       :key="index">

    <div class="question-title">
      <h4> {{ $t('DIAS.PHISHING.QUESTION') + " " + (index + 1) }}      </h4>
      <InlineButton v-if="emails.length > minEmails"
                    :text="$t('DIAS.PHISHING.REMOVE_EMAIL')"
                    :icon="require('@/assets/icons/icn_delete.svg')"
                    @click="removeEmail(index)"
      />

    </div>
    <div class="question-content-wrapper">
      <InputField
        :field-title="$t('DIAS.PHISHING.SENDER')"
        :field-name="`name`"
        :rules="'required'"
        :type="'text'"
        :value="email.sender"
        :bind-value="true"
        :on-update="(value) => setSender(index,value)"
        :placeholder="$t('DIAS.PHISHING.SENDER_PLACEHOLDER')"
        class="form-element"
      />
      <!-- placeholder added here as @ symbol doesn't compile from i18n -->
      <InputField
        :field-title="$t('DIAS.PHISHING.EMAIL_OR_PHONE')"
        :field-name="`email`"
        :rules="'required'"
        :type="'text'"
        :value="email.emailOrPhoneNumber"
        :bind-value="true"
        :on-update="(value) => setEmailOrPhone(index,value)"
        :placeholder="'Bijv. voorbeeld \u0040 email.nl of +31 123456789'"
        class="form-element"
      />
      <InputField
        :field-title="$t('DIAS.PHISHING.SUBJECT')"
        :field-name="`subject`"
        :rules="'required'"
        :type="'text'"
        :value="email.topic"
        :bind-value="true"
        :on-update="(value) => setSubject(index,value)"

        :placeholder="$t('DIAS.PHISHING.SUBJECT_PLACEHOLDER')"
        class="form-element"
      />
      <RichTextField :field-title="$t('DIAS.PHISHING.CONTENT')"
                     :placeholder="$t('DIAS.PHISHING.CONTENT_PLACEHOLDER')"
                     :value="email.message"
                     :input-callback="(value) => setContent(index,value)"
      />
      <OkOrNotToggle :start-value="email.isReal"
                     :text-ok="$t('DIAS.PHISHING.IS_REAL')"
                     :text-not-ok="$t('DIAS.PHISHING.IS_PHISHING')"
                     @toggle="(value) => setIsReal(index,value)"/>
      <LargeInputField
        :field-title="$t('DIAS.FEEDBACK')"
        :field-name="'feedback'"
        :inline-subtitle="`(${$t('OPTIONAL')})`"
        :type="'text'"
        :value="email.feedback"
        :on-update="(value) => setFeedback(index,value)"
        :bind-value="true"
        :rules="'optional'"

        :placeholder="$t('DIAS.FEEDBACK_PLACEHOLDER')"
        class="form-element"
      />
    </div>
  </div>
  <InlineButton v-if="emails.length < maxEmails"
                :text="$t('DIAS.PHISHING.ADD_EMAIL')"
                :icon="require('@/assets/icons/icn_add.svg')"
                @click="addEmail()"
  />
</template>

<script>
import InputField from '@/components/yo-form/InputField'
import LargeInputField from '@/components/yo-form/LargeInputField'
import InlineButton from '../../../elements/basic/InlineButton.vue'
import OkOrNotToggle from './OkOrNotToggle.vue'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import { TYPES_TRUE_OR_FALSE } from '@/utils/helpers/mission/SelectAnswerConsts'

export default {
  name: 'Phishing',
  components: {
    OkOrNotToggle,
    InlineButton,
    InputField,
    RichTextField,
    LargeInputField
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    showErrors: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['data', 'verify'],
  data() {
    const defaultEmail = {
      sender: null,
      emailOrPhoneNumber: null,
      topic: null,
      message: null,
      isReal: false,
      feedback: null
    }
    const emails = this.initialData ? this.convertInitialData() : [{ ...defaultEmail }]
    return {
      minEmails: 1,
      maxEmails: 10,
      defaultEmail,
      emails
    }
  },
  watch: {
    emails: {
      handler(val) {
        this.onUpdate()
      },
      deep: true
    }
  },
  mounted() {
    this.onUpdate()
  },
  methods: {
    convertInitialData() {
      const questions = this.initialData.contentQuestionJson
      const newDefaultSlides = questions.subSlides.map((slide, index) => {
        return {
          sender: slide.sender,
          emailOrPhoneNumber: slide.emailorPhoneNumber,
          topic: slide.topic,
          message: slide.message,
          feedback: slide.feedback,
          isReal: slide.correctAnswer === 1
        }
      })
      return newDefaultSlides
    },
    addEmail() {
      this.emails.push({ ...this.defaultEmail })
    },
    removeEmail(index) {
      this.emails.splice(index, 1)
    },
    setSender(index, sender) {
      this.emails[index].sender = sender
    },
    setEmailOrPhone(index, emailOrPhone) {
      this.emails[index].emailOrPhoneNumber = emailOrPhone
    },
    setSubject(index, topic) {
      this.emails[index].topic = topic
    },
    setContent(index, content) {
      this.emails[index].message = content
    },

    setIsReal(index, isReal) {
      this.emails[index].isReal = isReal
    },
    setFeedback(index, feedback) {
      this.emails[index].feedback = feedback
    },
    onUpdate() {
      this.$emit('data', {
        slideType: TYPES_TRUE_OR_FALSE.REAL_OR_PHISHING,
        emails: this.emails
      })
      this.$emit('verify', this.isValid())
    },
    isValid() {
      return true
    }


  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";



.question-content-wrapper{
  margin: rem(20) rem(0);
  padding-left: rem(20);
  border-left: black 2px solid;
}

.question-title{
  display: flex;
  justify-content: space-between;
}

</style>
