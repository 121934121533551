<template>
  <div v-for="(message,index) in messages"
       :key="index"
       class="chat-element">
    <InputField
      :field-title=" $t(senders[index] ? 'DIAS.CHAT.SENDER' : 'DIAS.CHAT.RECIEVER')"
      :field-name="`statement${index}`"
      :rules="'required'"
      :type="'text'"
      :bind-value="true"
      :value="message"
      :on-update="(value) => setMessage(index,value)"
      :placeholder="$t('DIAS.CHAT.CHAT_PLACEHOLDER')"
      class="form-element"
    >
      <template #right-side-content>
        <div class="right-side-content">
          <Checkbox :enabled="senders[index]" @click="toggleSender(index)"/>
          <p>Afzender</p>
          <InlineButton v-if="index > 0"
                        :text="$t('DELETE')"
                        class="delete-chat-button"
                        :icon="require('@/assets/icons/icn_delete.svg')"
                        @click="deleteMessage(index)" />
        </div>

      </template>
    </InputField>

  </div>
  <InlineButton v-if="messages.length < maxMessages"
                :text="$t('DIAS.CHAT.ADD_CHAT')"
                :icon="require('@/assets/icons/icn_add.svg')"
                @click="addMessage()" />

  <OkOrNotToggle :start-value="isOk"
                 @toggle="(value) => setOk(value)"/>
  <LargeInputField
    :field-title="$t('DIAS.FEEDBACK')"
    :field-name="`feedback`"
    :type="'text'"
    :value="feedback"
    :bind-value="true"
    :rules="'optional'"

    :placeholder="$t('DIAS.FEEDBACK_PLACEHOLDER')"
    :on-update="(value) => $emit('data','feedback',value)"
    class="form-element"
  />
</template>

<script>
import InlineButton from '@/components/elements/basic/InlineButton'
import LargeInputField from '@/components/yo-form/LargeInputField.vue'
import OkOrNotToggle from '../OkOrNotToggle.vue'
import InputField from '@/components/yo-form/InputField'
import Checkbox from '@/components/elements/basic/Checkbox'

export default {
  name: 'Chat',
  components: {
    LargeInputField,
    InlineButton,
    OkOrNotToggle,
    InputField,
    Checkbox
  },
  props: {
    slideData: {
      type: Object,
      required: true
    },
  },
  emits: ['data', 'verify'],
  data() {
    return {
      maxMessages: 30,
      minMessages: 1,
    }
  },
  computed: {
    messages() { return this.slideData.messages ?? ['', ''] },
    senders() { return this.slideData.senders ?? [true, false] },
    isOk() { return this.slideData.isOk ?? false },
    feedback() { return this.slideData.feedback ?? '' }
  },
  watch: {
    slideData: {
      handler(val) {
        this.$emit('verify', this.isValid())
      },
      deep: true
    }
  },
  mounted() {
    this.$emit('verify', this.isValid())
  },
  methods: {
    addMessage() {
      if (this.messages.length < this.maxMessages) {
        this.updateMessage(this.messages.concat(''))
      }
    },
    deleteMessage(index) {
      if (this.messages.length > this.minMessages) {
        const newMessages = [...this.messages]
        newMessages.splice(index, 1)
        this.updateMessage(newMessages)
      }
    },
    setMessage(index, value) {
      const newMessages = [...this.messages]
      newMessages[index] = value
      this.updateMessage(newMessages)
    },
    updateMessage(newMessages) {
      this.$emit('data', 'messages', newMessages)
    },
    toggleSender(index) {
      const newSenders = [...this.senders]
      newSenders[index] = !newSenders[index]
      this.$emit('data', 'senders', newSenders)
    },
    setOk(value) {
      this.$emit('data', 'isOk', value)
    },
    isValid() {
      return this.messages.every(message => message && message !== '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.delete-chat-button{
  position: relative;
}

.chat-element{
  margin: rem(30) rem(0)
}

.right-side-content{
  position: absolute;
  display: flex;
  top: -40px;
  gap: rem(20);
  left: 30px;
  align-items: center;
}

</style>
