<template>
  <div class="feedback-input">
    <LargeInputField
      :field-title="$t('DIAS.FEEDBACK')"
      :field-name="'feedback'"
      :inline-subtitle="`(${$t('OPTIONAL')})`"
      :type="'text'"
      :rules="'optional'"
      :placeholder="$t('DIAS.FEEDBACK_PLACEHOLDER')"
      :on-update="onFeedbackInput"
      :value="initialData ? initialData.feedback : ''"
      class="form-element"
      style="margin-top: 20px;"
    />
    <SelectVideo
      :title="$t('DIAS.VIDEO_CORRECT_TITLE')"
      :initial-selection="initialData ? initialData.correctVideo : null"
      @selected="correctVideoSelected"
    />
    <SelectVideo
      :title="$t('DIAS.VIDEO_WRONG_TITLE')"
      :initial-selection="initialData ? initialData.incorrectVideo : null"
      @selected="wrongVideoSelected"
    />
  </div>
</template>

<script>
import LargeInputField from '@/components/yo-form/LargeInputField'
import SelectVideo from '@/components/elements/inputFields/SelectVideo'
import { ref } from 'vue'

export default {
  name: 'FeedbackInput',
  components: {
    LargeInputField,
    SelectVideo
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['data'],
  setup(props, { emit }) {
    const correctVideo = ref(null)
    const wrongVideo = ref(null)
    const feedbackText = ref('')

    function correctVideoSelected(video) {
      correctVideo.value = video
      emitData()
    }
    function wrongVideoSelected(video) {
      wrongVideo.value = video
      emitData()
    }
    function onFeedbackInput(input) {
      feedbackText.value = input
      emitData()
    }

    function emitData() {
      const data = {
        correctVideo: correctVideo.value,
        wrongVideo: wrongVideo.value,
        feedbackText: feedbackText.value
      }
      emit('data', data)
    }

    return {
      correctVideoSelected,
      wrongVideoSelected,
      onFeedbackInput,
      wrongVideo,
      correctVideo
    }
  }
}
</script>

<style scoped>

</style>
