// Maps slide types for DiaFormSelectAwnser.vue into the ID's of the type dropdown.
export const slideTypeMapper = Object.freeze({
  // AnswerPoll
  4: 2,
  // Article
  5: 3,
  // AnswerText
  7: 0,
  // AnswerText
  8: 0,
  // AnswerImage
  9: 1,
  // AnswerImage
  10: 1,
  // Labyrinth
  11: 4,
  // Real or phishing
  13: 3,
  //Netiquette
  12: 3,
})


export const TYPES_TRUE_OR_FALSE = Object.freeze({
  ARTICLE: 5,
  NETIQUETTE: 12,
  REAL_OR_PHISHING: 13,

})
