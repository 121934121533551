<template>
  <LargeInputField
    :field-title="$t('DIAS.PHISHING.CONTENT')"
    :field-name="`statement`"
    :rules="'required'"
    :value="message"
    :bind-value="true"

    :on-update="(value) => updateData('message',value)"
    :type="'text'"
    :placeholder="$t('DIAS.PHISHING.CONTENT_PLACEHOLDER')"
    class="form-element"
  />
  <OkOrNotToggle :start-value="isOk"
                 @toggle="(value) => setOk(value)"/>
  <LargeInputField
    :field-title="$t('DIAS.FEEDBACK')"
    :field-name="`feedback`"
    :type="'text'"
    :value="feedback"
    :rules="'optional'"

    :bind-value="true"
    :placeholder="$t('DIAS.FEEDBACK_PLACEHOLDER')"
    :on-update="(value) => updateData('feedback',value)"
    class="form-element"
  />
</template>

<script>
import LargeInputField from '../../../../yo-form/LargeInputField.vue'
import OkOrNotToggle from '../OkOrNotToggle.vue'

export default {
  name: 'FriendRequest',
  components: {
    LargeInputField,
    OkOrNotToggle
  },
  props: {
    slideData: {
      type: Object,
      required: true
    }
  },
  emits: ['data', 'verify'],
  computed: {
    message() { return this.slideData.message ?? '' },
    isOk() { return this.slideData.isOk ?? false },
    isValid() { return this.slideData.message && this.slideData.message !== '' },
    feedback() { return this.slideData.feedback ?? '' }
  },
  watch: {
    slideData: {
      handler(val) {
        this.$emit('verify', this.isValid)
      },
      deep: true
    }
  },
  mounted() {
    this.$emit('verify', this.isValid)
  },
  methods: {
    setOk(isOk) {
      this.updateData('isOk', isOk)
    },
    updateData(key, value) {
      this.$emit('data', key, value)
    },
  }


}
</script>

<style>

</style>
