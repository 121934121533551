<template>
  <h4 class="image-title"> {{ $t('DIAS.COMMENT.IMAGE') }}</h4>
  <ImageInput :start-file="image"
              :media-files="mediaFiles"
              :error-text="imageError"
              @file="(file) => setImage(file)" />
  <div v-for="(comment,index) in comments"
       :key="index"
       class="comment-container">
    <InputField
      :field-title="$t('DIAS.COMMENT.COMMENT') + ' ' + (index + 1)"
      :field-name="`statement + ${index}`"
      :rules="'required'"
      :type="'text'"
      :value="comment"
      :bind-value="true"
      :on-update="(value) => setComment(index,value)"
      :placeholder="$t('DIAS.COMMENT.COMMENT_PLACEHOLDER')"
      class="form-element"
    />
  </div>
  <InlineButton v-if="comments.length < maxComments"
                :text="$t('DIAS.COMMENT.ADD_COMMENT')"
                :icon="require('@/assets/icons/icn_add.svg')"
                @click="addComment()"
  />
  <OkOrNotToggle :start-value="isOk"
                 @toggle="(value) => setOk(value)"/>
  <LargeInputField
    :field-title="$t('DIAS.FEEDBACK')"
    :field-name="`feedback`"
    :type="'text'"
    :rules="'optional'"

    :value="feedback"
    :bind-value="true"
    :placeholder="$t('DIAS.FEEDBACK_PLACEHOLDER')"
    :on-update="(value) => $emit('data','feedback',value)"
    class="form-element"
  />
</template>

<script>
import LargeInputField from '@/components/yo-form/LargeInputField.vue'

import InputField from '@/components/yo-form/InputField'
import ImageInput from '@/components/elements/inputFields/ImageInput.vue'
import OkOrNotToggle from '../OkOrNotToggle.vue'
import InlineButton from '@/components/elements/basic/InlineButton.vue'


export default {
  name: 'Comment',
  components: {
    LargeInputField,
    InputField,
    ImageInput,
    OkOrNotToggle,
    InlineButton
  },
  props: {
    slideData: {
      type: Object,
      required: true
    },
    mediaFiles: {
      type: Object,
      default: null
    }
  },
  emits: ['data', 'verify'],
  data() {
    return {
      maxComments: 4,
      minComments: 1,
      imageError: ''
    }
  },
  computed: {
    image() { return this.slideData.image ?? null },
    comments() { return this.slideData.comments ?? ['', ''] },
    isOk() { return this.slideData.isOk ?? false },
    feedback() { return this.slideData.feedback ?? '' }

  },
  watch: {
    slideData: {
      handler(val) {
        this.$emit('verify', this.isValid())
      },
      deep: true
    }
  },
  mounted() {
    this.$emit('verify', this.isValid())
  },
  methods: {
    setImage(image) {

      this.$emit('data', 'image', image)
    },
    addComment() {
      if (this.comments.length <= this.maxComments) {
        this.updateComments(this.comments.concat(''))
      }
    },
    removeComment(index) {
      if (this.comments.length > this.minComments) {
        const newComments = [...this.comments]
        newComments.splice(index, 1)
        this.updateComments(newComments)
      }
    },
    setComment(index, value) {
      const newComments = [...this.comments]
      newComments[index] = value
      this.updateComments(newComments)
    },
    updateComments(newComments) {
      this.$emit('data', 'comments', newComments)
    },
    setOk(value) {
      this.$emit('data', 'isOk', value)
    },
    isValid() {
      if (this.image === null) {
        this.imageError = 'De afbeelding is verplicht.'
      } else {
        this.imageError = ''
      }

      return this.image && this.comments.every(x => x && x !== '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.image-title{
  padding-top: rem(20);
}

.comment-container{
  margin: rem(30) rem(0);
}


</style>
