<template>
  <FormWrapper :send-form-data="validateData" class="page-form">
    <template #form-content>

      <h3>{{ $t('DIAS.IMAGE') }} {{ $t('DIA') }}</h3>

      <InputField :field-title="$t('DIAS.TITLE')" :field-name="'title'" :rules="'required'" :type="'text'"
        :value="initialData ? initialData.title : ''" :placeholder="$t('DIAS.TITLE_PLACEHOLDER')"
        :cy-selector="'name-input'" class="form-element" />
      <RichTextField :field-title="$t('DIAS.DESCRIPTION')" :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
        :input-callback="descriptionInput" :value="initialData ? initialData.description : ''"
        :error-text="textInputError" />
      <h4>{{ $t('DIAS.FILE') }}</h4>
      <ImageInput :start-file="initialData ? initialData.contentQuestionJson.content : null"
        :error-text="imageInputError" :media-files="initialData ? initialData.mediaFiles : []" @file="updateImage" />
      <InputField v-show="!hideBits" :field-title="$t('DIAS.BITS')" field-name="bitsAwarded" rules="required" type="number"
        :value="hideBits ? '0' : initialData ? initialData.bitsAwarded : ''" :placeholder="$t('DIAS.BITS_PLACEHOLDER')"
        cy-selector="bits-input" class="form-element" :input-icon="bitsIcon" />
    </template>

    <template #button-submit>
      <button type="submit" class="pink-button submit-button">
        {{ $t('CREATE_MISSION.SAVE_AND_CONTINUE') }}
      </button>
    </template>
  </FormWrapper>
</template>

<script>
import { ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { CREATE_DIA } from '@/store/modules/cms/missions/actions'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import ImageInput from '@/components/elements/inputFields/ImageInput'
import bitsIcon from '@/assets/icons/icn_bits_transparent.svg'
export default {
  name: 'Details',
  components: {
    InputField,
    FormWrapper,
    RichTextField,
    ImageInput,
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    initialData: { //filled if editing
      type: Object,
      default: null
    },
    hideBits: {
      type: Boolean,
      default: false
    }
  },
  setup(proxy) {
    const store = useStore()
    const router = useRouter()
    const slideId = proxy.initialData ? proxy.initialData.id : null
    const descriptionValue = ref(proxy.initialData ? proxy.initialData.description : '')
    const textInputError = ref(null)
    const imageInputError = ref(null)

    function descriptionInput(data) {
      descriptionValue.value = data
    }
    function validateData(data) {
      if (!handleErrors()) {
        return
      }

      data.missionId = proxy.id
      data.image = image.value
      data.description = descriptionValue.value
      store.dispatch(CREATE_DIA, { data, type: 'image', slideId }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
        }
      })
    }

    const image = ref(null)
    function updateImage(data) {
      image.value = data
    }

    function handleErrors() {
      let success = true
      clearErrors()
      if (descriptionValue.value === '') {
        setError(textInputError, 'De tekst is verplicht.')
        success = false
      }
      if (image.value === null) {
        setError(imageInputError, 'De afbeelding is verplicht')
        success = false
      }

      return success
    }

    function setError(error, text) {
      error.value = text
    }

    function clearErrors() {
      textInputError.value = ''
      imageInputError.value = ''
    }

    return {
      validateData,
      updateImage,
      descriptionInput,
      textInputError,
      imageInputError,
      bitsIcon
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


h3 {
  margin-bottom: rem(20);
}

h4 {
  color: var(--blue_dark);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-top: rem(20);
}

.File {
  margin-right: 100%;
  width: rem(215);
}
</style>
