<template>
  <div v-for="(image,index) in images"
       :key="index"
       class="image-input">
    <h4>{{ $t('DIAS.ANSWER') }} {{ index + 1 }}</h4>
    <div class="individual-input">
      <ImageInput
        :start-file="images[index].file"
        :media-files="initialData ? initialData.mediaFiles : null"
        :error-text="images[index].file === null && showErrors ? 'Voeg een bestand toe.' : ''"
        @file="(file) => setImage(index,file)"
      />
      <correct-or-delete :show-remove="images.length > minImages"
                         :close="true"
                         :start-correct="image.correct"
                         @correct="(correct) => toggleCorrect(index,correct)"
                         @delete="removeImage(index)"/>
    </div>
  </div>
  <p v-if="showErrors && noCorrectSet()" class="text-error">{{ $t('DIAS.NO_ANSWER') }}</p>
  <InlineButton v-if="images.length < maxImages"
                :text="$t('DIAS.SELECT_ANSWER.ADD_MORE_ANSWERS')"
                :icon="require('@/assets/icons/icn_add.svg')"
                :icon-color="'var(--blue_link)'"
                @click="addImage()"
  />
</template>

<script>
import InlineButton from '@/components/elements/basic/InlineButton'
import CorrectOrDelete from '../../../elements/inputFields/CorrectOrDelete.vue'
import ImageInput from '../../../elements/inputFields/ImageInput.vue'
export default {
  name: 'AnswerImage',
  components: {
    ImageInput,
    CorrectOrDelete,
    InlineButton
  },
  props: {
    showErrors: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      required: false,
      default: null
    },
  },
  emits: ['verify', 'data'],
  data() {
    const defaultImage = {
      file: null,
      correct: false,
    }
    const images = this.initialData ? this.convertInitialData() : [{ ...defaultImage }, { ...defaultImage }]
    return {
      minImages: 2,
      maxImages: 3,
      defaultImage,
      images,
    }
  },
  mounted() {
    this.emitFiles()
    this.verify()
  },
  methods: {
    convertInitialData() {
      const questions = this.initialData.contentQuestionJson
      const answer = this.initialData.contentAnswerJson.correctAnswer
      function correct(index) {
        if (typeof answer === 'number') {
          return index + 1 === answer
        }
        return answer.includes(index + 1)
      }

      const images = questions.answers.map((x, index) => {
        return {
          file: x.answer,
          correct: correct(index)
        }
      })
      return images
    },
    addImage() {
      if (this.images.length >= this.maxImages) {
        return
      }
      this.images.push({ ...this.defaultImage })
      this.emitFiles()
      this.verify()
    },
    removeImage(index) {
      if (this.images.length <= this.minImages) {
        return
      }
      this.images.splice(index, 1)
      this.emitFiles()
      this.verify()
    },
    setImage(index, file) {
      this.images[index].file = file
      // this.images[index].correct = true
      this.emitFiles()
      this.verify()
    },
    noCorrectSet() {
      return this.images.every(x => !x.correct)
    },
    toggleCorrect(index, correct) {
      this.images[index].correct = correct
      this.emitFiles()
      this.verify()
    },
    emitFiles() {
      this.$emit('data', this.images)
    },
    verify() {
      let valid = true
      this.images.forEach(image => {
        if (image.file === null) {
          valid = false
        }
      })
      if (this.noCorrectSet()) {
        valid = false
      }
      this.$emit('verify', valid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.image-input{
  display: flex;
  flex-direction: column;
  gap: rem(20);
  margin-top: rem(20);
  margin-bottom:rem(20);
}

.correct-delete{
  left: rem(400)
}

.individual-input{
  display: flex;
  align-items: center;
  position: relative;
}

.text-error{
  margin-top: 0;
  margin-bottom: rem(20)
}
</style>
